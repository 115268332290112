@tailwind base;

@tailwind components;

@tailwind utilities;

.custom-gradient {
  color: #0000;
  background: linear-gradient(to right, #06ab8c, #02ae5c, #53ba69) text;
}

body {
  background-color: #fff;
}

button.link {
  transition: filter .1s linear, transform .1s ease-in-out;
}

button.link:hover {
  color: #fff;
  background: linear-gradient(to right, #06ab8c, #02ae5c, #53ba69);
}

div.inner {
  z-index: -99999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: inset 0 0 700px #000;
  position: fixed !important;
}

svg#circuit {
  opacity: .5;
  z-index: -99999;
  background-color: #111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed !important;
}

.path {
  animation: 20s linear infinite draw;
}

.path-01 {
  animation-delay: 0s;
}

.path-02 {
  animation-delay: 1s;
}

.path-03 {
  animation-delay: 2s;
}

.path-04 {
  animation-delay: 3s;
}

.path-05 {
  animation-delay: 4s;
}

@keyframes draw {
  0% {
  }

  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}
/*# sourceMappingURL=index.ebc86233.css.map */
