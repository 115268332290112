@tailwind base;
@tailwind components;
@tailwind utilities;

/* .link:hover {
  filter: drop-shadow(0px 0.25rem 8px #fff);
} */

.custom-gradient {
  background: linear-gradient(to right, #06ab8c, #02ae5c, #53ba69);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
}

body {
  background-color: #fff;
}

button.link {
  transition: filter 0.1s linear, transform 0.1s ease-in-out;
}

button.link:hover {
  background: linear-gradient(to right, #06ab8c, #02ae5c, #53ba69);
  color: white;
}

div.inner {
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 700px #000;
  overflow: hidden;
  z-index: -99999;
}

svg#circuit {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #111;
  opacity: 0.5;
  z-index: -99999;
}
.path {
  animation: draw 20s infinite;
  animation-timing-function: linear;
}
.path-01 {
  animation-delay: 0s;
}
.path-02 {
  animation-delay: 1s;
}
.path-03 {
  animation-delay: 2s;
}
.path-04 {
  animation-delay: 3s;
}
.path-05 {
  animation-delay: 4s;
}

@keyframes draw {
  0% {
  }
  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 1;
  }
}
